import React from "react";
import FirstContactAdSpace from "/src/containers/first-contact/ad-space";
import Navbar from "/src/containers/navbar";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import { LocalizedRouter as Router } from "gatsby-theme-i18n";

import config from "/src/config.js";
import SEO from "/src/components/seo";

const ConsultAdSpace = () => {
  return (
    <>
      <SEO
        title={`${config.CLIENT_NAME} - Advertising`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <Router basePath={"/"}>
          <FirstContactAdSpace path={"consult-adspace/:assetId"} />
        </Router>
      </BacThemeProvider>
    </>
  );
};

export default ConsultAdSpace;
